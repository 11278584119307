import React from "react";
import insta from "../assets/image/instagram.svg"
import fb from "../assets/image/facebook.svg"
import twitter from "../assets/image/twitter.svg"

const Footer = () => {
  return (
    <div
      id="container"
      className="mt-24 bg-[#c4d2282a] w-full border-y-4 border-[#C3D228] py-16"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 px-10 md:px-20 lg:px-32">
        {/* Left Section */}
        <div className="flex flex-col gap-5">
          <p className="text-sm text-gray-700 grid lg:pt-12">
            We understand that every student has different needs and capabilities, which is why we create such a wonderful and unique curriculum that is the best fit for every student.
          </p>
          <div className="text-base font-semibold mt-4">Let's Get Social :
         <div className=" flex  gap-4 pt-4">
          <a  href="https://www.instagram.com/resonance.indore/?utm_source=qr&igshid=YTlmZjI0ZWMzOA%3D%3D">
          <img src={insta} className="h-8 w-8"  /></a>
           <img src= {fb} className="h-8 w-8" />
          <img src={twitter} className="h-8 w-8" />
          </div>
        </div>
        </div>
        {/* Our Centers */}
        <div>
          <h1 className="text-lg font-semibold mb-6">Our Centers</h1>
          <div className="flex flex-col gap-4 text-gray-600">
            <h3>Hyderabad</h3>
            <h3>Indore</h3>
            <h3>Gwalior</h3>
            <h3>Bhopal</h3>
            <h3>Raipur</h3>
            <h3>Kota</h3>
          </div>
        </div>

        {/* Quick Links */}
        <div>
          <h1 className="text-lg font-semibold mb-6">Quick Links</h1>
          <div className="flex flex-col gap-4 text-gray-600">
            <h3>Network Contact Info</h3>
            <h3>Feedback</h3>
            <h3>Terms of Service</h3>
            <h3>Privacy Policy</h3>
            <h3>Refund</h3>
            <h3>Cancellation Policy</h3>
          </div>
        </div>

        {/* Popular Exams */}
        <div>
          <h1 className="text-lg font-semibold mb-6">Popular Exams</h1>
          <div className="flex flex-col gap-4 text-gray-600">
            <h3>IIT-JEE</h3>
            <h3>NEET</h3>
            <h3>NDA</h3>
            <h3>School Prep</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
