import React, { useState } from "react";
import PngImg from "../assets/registerPoster2.jpg";
import { HowToRegister } from "../data/howToRegisterData";


const ResoSatPage = () => {
  const [veiwImage, setViewImage] = useState();
  const [previewImg, setPreviewImg] = useState(false);

  return (
    <>
      <div className=" w-full lg:w-4/5 md:w-4/5 flex-col lg:flex-row md:flex-row xl:flex-row sm:flex-col flex items-center justify-evenly rounded-xl overflow-hidden mt-3 mx-auto bg-[#014d99] text-white p-7">
        <div className="flex flex-col flex-1 gap-y-3">
          <h1 className="text-3xl font-bold text-white ">
            ResoSAT 2024: Resonance Scholarships Cum Admission Test
          </h1>
          <p className="text-xl">
            Exclusive for students in classes <br /> 8<sup>th</sup> , 9
            <sup>th</sup> , 10 <sup>th</sup> , 11th <sup>th</sup> , 12{" "}
            <sup>th</sup> and 12<sup>th</sup> pass
          </p>
          <p>
            Win upto{" "}
            <mark className="px-2 bg-blue-400 rounded-lg font-bold">
              90% scholarships
            </mark>{" "}
            on our Courses (JEE, NEET, and Foundation).
          </p>{" "}
          <a href="/">
            <div className="bg-[#8bc018] cursor-pointer mt-3 hover:scale-95 duration-500 shadow-2xl px-5 py-3 text-white w-fit font-bold rounded-md">
              Register Now
            </div>
          </a>
        </div>
        <div className="arrow-sign flex-1 flex justify-center d-lg-block d-none">
          <img src={PngImg} alt="ResoSAT 2024" className="w-[300px]" />
        </div>
      </div>
      <div id="How_to_register" className="mt-8 mb-5">
        <div className="flex flex-col justify-center items-center">
          <span className="lg:text-xl text-sm font-semibold uppercase text-[#014d99] ">
            Registration Process{" "}
          </span>
          <h4 className="font-bold text-2xl lg:text-4xl xl:text-4xl ">
            How to Register
          </h4>
        </div>
        {/* /===============card section===================== */}
        <div>
          <div className=" w-full lg:w-4/5 md:w-4/5 mx-auto px-6 py-3 border-indigo-500 border-2 rounded-lg mt-5 shadow-2xl">
            <p className="rounded-xl text-3xl w-fit  text-black px-3 py-2">
              👉
              <a href="https://resosat.resonanceindore.com">
                <i className="underline text-blue-600 hover:text-green-500 hover:scale-[0.9]">click here</i>
              </a>{" "}
              to Login and start Your Test{" "}
            </p>
            <h6 className="text-orange-700 mt-3 font-bold text-md lg:text-2xl md:text-lg xl:text-2xl">
              Online Registration
            </h6>
            {/* <p className="border-2 rounded-xl py-1 px-2 shadow-sm lg:text-xl text-md mt-2 font-bold">
              <Link
                className="underline hover:text-green-800 duration-200 text-blue-900"
                to={"/register-resoSAT"}
              >
                Click Here
              </Link>{" "}
              to fill the form for online and offline mode exam
            </p> */}
            {/* {=============how to instructions====================} */}
            <ol className=" list-decimal pt-3 list-inside">
              {/* -----step: 1 */}
              {HowToRegister.map((instruction, index) => (
                <>
                  <div
                    className="flex flex-col lg:justify-between lg:flex-row lg:items-center"
                    key={index}
                  >
                    <div>
                      <li className="font-bold text-xl my-2">
                        {instruction.step}
                      </li>
                      <p className=" text-xl text-[#014d99]">
                        {" "}
                        {instruction.description} <br />{" "}
                        <em className="text-red-600">
                          note :{instruction.note}
                        </em>
                      </p>
                    </div>
                    {/* ===---------screen shot------------===== */}
                    {/* <div className="bg-[#014d99] my-2 w-11/12 h-72"></div> */}
                    <img
                      onClick={() => {
                        setPreviewImg(instruction.screenshot);
                        setViewImage(true);
                      }}
                      src={instruction.screenshot}
                      className=" h-72 my-e"
                      alt="inst"
                    />
                  </div>
                  <br />
                  <hr />
                  <br />
                </>
              ))}
            </ol>
          </div>
        </div>
      </div>
      <div id="registerForm">{/* <ResosatRegistrationForm/> */}</div>
      {veiwImage && (
        <section
          className="fixed z-[10000] flex items-center justify-center bg-[#f5f5f5c8] h-screen w-screen top-0 left-0 modal"
          onClick={(e) => {
            if (e.target.classList.contains("modal")) {
              setViewImage(false);
            }
          }}
        >
          {" "}
          <div className=" w-11/12 md:w-[80vw] lg:w-[75vw] m-auto rounded-2xl p-4 flex items-center flex-col ">
            <img className="w-[100%]  " src={previewImg} alt="instruction" />
          </div>
        </section>
      )}
    </>
  );
};

export default ResoSatPage;
