import React from "react";
import { stuData } from "../data/StudentReviewData";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";

import circle from "../assets/image/Why choose reso image 400X375 - Copy.jpg";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import { Banner1, Banner2, Banner3 } from "../data/ImageData";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

const StarRating = ({ rating }) => {
  return (
    <>
      {" "}
      <div className="flex justify-center items-center pb-4">
        {rating === 3 && (
          <div className="flex items-center gap-1">
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaRegStar color="black" size={18} />
            <FaRegStar color="black" size={18} />
          </div>
        )}
        {rating === 3.5 && (
          <div className="flex items-center gap-1">
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStarHalfAlt size={18} />
            <FaRegStar color="black" size={18} />
          </div>
        )}
        {rating === 4 && (
          <div className="flex items-center gap-1">
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />

            <FaRegStar color="black" size={18} />
          </div>
        )}
        {rating === 4.5 && (
          <div className="flex items-center gap-1">
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStarHalfAlt size={18} />
          </div>
        )}
        {rating === 5 && (
          <div className="flex items-center gap-1">
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
            <FaStar size={18} color="black" />
          </div>
        )}
      </div>
    </>
  );
};

const StudentCommentSection = () => {
  return (
    <div className=" m-auto py-6  ">
      <h3 className="text-center text-black text-3xl mb-10 font-bold font-poppins ">
        Students <span className="font-bold gradient-text">Comments</span>
      </h3>

      <Swiper
        className="mySwiper w-11/12"
        loop={true}
        slidesPerView={1}
        spaceBetween={32}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 2000 }}
        pagination={{ clickable: true,}}
         breakpoints={{
           640: {
             slidesPerView: 1,
             spaceBetween: 32,
           },
           768: {
             slidesPerView: 2,
             spaceBetween: 32,
           },
           1024: {
             slidesPerView: 3,
             spaceBetween: 32,
           },
         }}
      >
        {stuData.map((comment, index) => (
          <SwiperSlide>
            <div
              key={index}
              className="p-6 shadow-lg bg-[#f9fbe5] min-h-[310px] rounded-xl border-2 border-[#B5C800] transition-all "
            >
              {/* ================================rating======================= */}
              {<StarRating rating={comment.rating} />}

              <h5 className="text-gray-700 text-lg font-semibold mb-4 line-clamp-5 font-poppins  ">
                {comment.testimonialText}
              </h5>

              <div className="flex items-center gap-4">
                <div className="w-16 h-16 overflow-hidden rounded-full">
                  <img
                    loading="lazy"
                    src={comment.authorImage}
                    alt={comment.authorName}
                    className="object-cover w-full h-full"
                  />
                </div>

                <div className="flex flex-col">
                  <span className="text-blue-900 font-bold font-poppins ">
                    {comment.authorName}
                  </span>
                  {comment.quoteImage && (
                    <img
                      loading="lazy"
                      src={comment.quoteImage}
                      alt="Quote"
                      className="w-16 h-6 mt-2"
                    />
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-pagination">

      </div>
    </div>
  );
};

export default StudentCommentSection;
