import React from 'react';
import what from "../assets/image/whatsapp-logo-1.jpeg"

function Whatsapp() {
  return (
    
    <div className="fixed right-4 bottom-24 z-50 sm:right-4 md:right-12 lg:right-10 xl:right-16">
      <a href="https://api.whatsapp.com/send?phone=7389934555" target="_blank" rel="noopener noreferrer" className="block relative w-16 h-16 group">
        <div className="absolute inset-0 rounded-full border-4 border-green-500 opacity-75 animate-ping"></div>
        <img
          src={what}
          alt="WhatsApp Icon"
          width={64}
          height={64}
          className="rounded-full border-2  relative  transition-transform duration-1000 group-hover:scale-110 "
        />
      </a>
    </div>
  );
}

export default Whatsapp;
