import React from "react";

import harshitJain from "../assets/image/students/HARSHIT JAIN.jpg"
import harshitquoteImg from "../assets/image/students/HARSHIT JAIN_AIR.jpg"
import vardanVerma from "../assets/image/students/VARDAN VERMA.jpg"
import vardanVermaQuote from "../assets/image/students/VARDAN VERMA_AIR.jpg"
import thanakMehul from "../assets/image/students/Thanak Mehul Parmar.jpg"
import Abhijeet from "../assets/image/students/Abhijeet Anand_AIR-15 JEE (Advanced)-2022.jpg"
import RishitAnand from "../assets/image/students/Rishit Agarwal_AIR-27 NEET (UG) - 2022_Online Classroom Student.jpg"


export const stuData = [
  {
    testimonialText:
      "“I am Harshit Jain, a proud RESONite and about to become an IITian now.I just want to show my gratitude towards Resonance, particularly their system of Study Hours. Aparte from classroom coaching, Self-studying is crucial in preparation and Resonance understands that. The study centres are open for all students throughout the day. There are proper slots, along with necessary short breaks. Students get a comfortable and disciplined environment, which they can't get at home or in a library.Study hours, excellent study material and constant support by Reso staff; all combined to provide a necessary driving force in my JEE preparation. Thank You Resonance.”",
    authorName: "HARSHIT JAIN",
    authorImage: harshitJain,
    quoteImage: harshitquoteImg,
    rating:5
  },
  {
    testimonialText:
      "“I scored 99.9984%ile in JEE Main June Attempt 2022. I Joined Resonance in 2020 and since then they have always been there to help me. The way they teach in Resonance is very Subtle and easy, so that even an average student can easily cope up with the competition that they have to face in exam. My personal experience is that even if you are scolded by the teachers, take it positively. Even I used to get scolded by my teachers a lot but it always helped me out at the end. I want to thank all my teachers for this support. Resonance played a vital role and provided with quality education and even the back end team was very cooperative. During corona phase where it was very difficult to take classes the non academic staff ensured that we should get Classes, DPPs and Study Material on time. Resonance study material is one of the best in terms of quality and selectivity of problems which was very helpful in encountering the problem and scenario that we face in exam.”",
    authorName: "VARDAN VERMA",
    authorImage: vardanVerma,
    quoteImage: vardanVermaQuote,
    rating:4
  },
  {
    testimonialText:
      "“My name is Thanak Mehul Parmar. I was a NEET (UG) 2022 aspirants and I secured AIR 209. I am so blessed with this incredible result because of the help of my mentors and my parents. I am grateful that I was in Resonance, Jamnagar centre and My Teachers have been there with me all through my journey of 11<sup>th</sup> and 12<sup>th</sup>. They helped me with my DPPs, Queries and Doubts. They were available all the time to solve all the doubts that I had and to teach me all the right things and solve my queries. I owe this result to Resonance and my Parents.”",
    authorName: "Thanak Mehul Parmar",
    authorImage:thanakMehul,
    rating:3.5
  },
  {
    testimonialText:
      "Resonance taught me to challenge my own abilities to get a better version of me.",
    authorName: "Abhijeet Anand",
    authorImage: Abhijeet,
    rating:4.5
  },
  {
    testimonialText:
      "All the directors at Resonance are amazing mentors they closely monitored my progress throughout my journey.",
    authorName: "Rishit Agarwal",
    authorImage:RishitAnand,
    rating:4
  },
];
