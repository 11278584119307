import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import {Home,CourseDetailPage,WhyResonance,ResonanceIndore,ResoSat, Result} from "./routes/elementRoutes";
import {routePath} from "./routes/path";
import Layout from "./components/Layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routePath.home} element={<Layout/>}>
          <Route index element={<Home/>} />
          <Route path={routePath.courseDetails} element={<CourseDetailPage/>}/>
          <Route path={routePath.whyResonance} element={<WhyResonance/>}/>
          <Route path={routePath.whyResonanceIndore} element={<ResonanceIndore/>}/>
          <Route path={routePath.resoSat} element={<ResoSat/>}/>
          <Route path={routePath.result} element={<Result/>}/>
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
