const CourseCard = ({ poster, courseName, courseDescription, link }) => {
  return (
    <div className="w-11/12 mx-auto ">
      <div className="bg-white shadow-md overflow-hidden mt-5 h-[510px] flex flex-col border-4 border-[rgb(161,210,63)] transition-all hover:scale-105 hover:shadow-[#aeff09] rounded-2xl">
        {/* Image Section */}
        <div>
          <img
            src={poster}
            alt={courseName}
            className="w-full h-full object-cover overflow-hidden border rounded-t-xl"
          />
        </div>

        {/* Content Section */}
        <div className="p-4 flex-1 flex flex-col justify-between">
          <div>
            <h2 className="text-2xl font-bold mb-2 text-center text-blue-900 ">
              <q>{courseName}</q>
            </h2>
            <p className="font-poppins text-sm text-black mb-2 px-2">
              {courseDescription}
            </p>
          </div>

          <div className="flex justify-center pb-1 ">
            <a href={link}>
              <button className="bg-blue-900 text-white px-6 py-2 rounded-2xl flex items-center justify-center ">
                <span> Know More</span>
                <svg className="ml-2" width={12} height={10}>
                  <use xlinkHref="#icon-arrow-right" />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
