import React, { useState } from "react";
import Logo from "../assets/image/Reso Logo White.png";
import { Link } from "react-router-dom";
import { routePath } from "../routes/path";
import { FaAngleDown } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

const navLinks = [
  { nav: "home", link: routePath.home },
  {
    nav: "About us",
    icon: <FaAngleDown />,
    subnav: [
      {
        nav: "Why Resonace Indore",
        link: routePath.whyResonanceIndore,
      },
      {
        nav: "Why Resonance",
        link: routePath.whyResonance,
      },
    ],
  },
  {
    nav: "Result",
    link: routePath.result,
  },
  {
    nav: "courses",
    icon: <FaAngleDown />,

    subnav: [
      {
        nav: "11th + 12th -JEE Main + Advance",
        link: "11th-course-jee",
      },
      {
        nav: "12th-JEE Main + Advanced",
        link: "12th-course-jee",
      },
      {
        nav: "Droppers-JEE Main + Advance",
        link: "Dropper-jee-mains-advance",
      },
      {
        nav: "11th+12th-NEET",
        link: "11th-neet",
      },
      {
        nav: "12th-NEET",
        link: "12th-neet",
      },
      {
        nav: "Droppers-NEET",
        link: "dropper-neet",
      },
      {
        nav: "Foundation-08th",
        link: routePath.foundation8th,
      },
      {
        nav: "Foundation-09th",
        link: "foundation-9th",
      },
      {
        nav: "Foundation-10th",
        link: "foundation-10th",
      },
    ],
  },
  {
    nav: "contact us",
    link: routePath.contactus,
  },
  {
    nav: "resosat",
    link: routePath.resoSat,
  },
];

const MobileNav = ({ menuOpen, setMenuOpen }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <section
      className={
        menuOpen
          ? "w-screen h-screen bg-[#0a3a7d41] opacity-100 backdrop:blur-lg fixed lg:hidden xl:hidden left-0"
          : "w-screen h-screen bg-[#0a3a7d41] opacity-0 duration-700  backdrop:blur-xl fixed lg:hidden xl:hidden left-0 -translate-x-full"
      }
    >
      {" "}
      <div
        className={
          menuOpen
            ? "h-screen duration-500  fixed left-0 top-0 w-screen lg:w-1/4  md:w-[40%] lg:hidden xl:hidden bg-[white]"
            : "h-screen duration-500 fixed -translate-x-full left-0 top-0 w-screen lg:w-1/4  md:w-[40%] lg:hidden xl:hidden bg-[#c2f9ff]"
        }
      >
        <div className="px-4 py-4  h-20 bg-[#0A557D] flex items-center justify-between">
          <img src={Logo} width={"200"} height="200" alt="logo_img" />
          <span onClick={() => setMenuOpen(false)}>
            <IoMdClose className="text-white text-4xl" />
          </span>
        </div>

        <div className="flex flex-col transition-all  ">
          {navLinks.map((navigation) => (
            <div key={navigation.nav}>
             <Link to={navigation.link}> <h1
                onClick={() =>{navigation.subnav ? setShowDropdown(navigation.nav):setMenuOpen(false)}}
                className={` flex transition-all capitalize text-xl items-center justify-between  border-b-2  py-4 px-4 ${navigation.nav === "resosat" ? "text-[#c3d228] font-semibold" :"text-black"}`}
              >
                {navigation.nav}
                {navigation.icon}
              </h1></Link>
              {navigation?.subnav && (
                <div
                  className={`transition-all list-none pl-4 text-sm ${
                    showDropdown === navigation.nav ? "block" : "hidden"
                  } `}
                >
                  {navigation.subnav.map((navi) => (
                    <Link
                    onClick={()=>setMenuOpen(false)}
                      to={
                        navigation.nav === "courses"
                          ? `course/${navi.link}`
                          : `${navi.link}`
                      }
                    >
                      {" "}
                      <li className="border-b-[1px] border-gray-600 border-collapse px-2 bg-white  py-2">
                        {navi?.nav}
                      </li>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}

        </div>

        <div
          className="flex text-black gap-3 text-sm mt-6 justify-center
      "
        >
          <a href="tel:+917389934555"><h1 className="cursor-pointer  hover:font-bold">7389934555</h1></a>
         <a href="tel:+917389934553"> <h1 className="cursor-pointer  hover:font-bold">7389934553</h1></a>
         <a href="tel:+917389934552"> <h1 className="cursor-pointer  hover:font-bold">7389934552</h1></a>
        </div>
      </div>
    </section>
  );
};

export default MobileNav;
