import { useLocation } from "react-router-dom";
import * as Images from "../data/ImageData";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const Form = () => {
  const contactRef = useRef();
  const location = useLocation();
  useEffect(() => {
    if (location.hash === "#contact") {
      console.log("contact page is on");
      contactRef.current.scrollIntoView(true);
    }
  }, [location]);

  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    let url = "";

    try {
      const response = await axios.post(url, data);
      if (response.data.status) {
        console.log("Response :", response.data);
      }else{
        throw new Error(response.data.message)
      }
    } catch (error) {
      alert("Registration failed. Please try again. ");
      console.log("error:", error);
    }
  };
  return (
    <>
      <section
        ref={contactRef}
        id="Contact"
        className="flex flex-col sm:flex-row items-center justify-center gap-4 lg:gap-6 text-2xl sm:text-3xl font-bold"
      >
        <img
          src={Images.tagline}
          className=" h-24 my-3 object-contain"
          alt="Logo"
        />
      </section>

      <div className=" flex items-center mt-5 justify-center ">
        <form
          className="w-full max-w-lg bg-white  rounded-lg "
          onSubmit={handleSubmit(onSubmit)}
        >
          <h2 className="text-4xl font-bold text-center mb-8">
            Get in Touch with <span className="gradient-text"> Resonance </span>
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-2 mb-4">
            <input
              type="text"
              id="fullName"
              {...register("name", {
                required: "Name is required",
              })}
              placeholder="FULL NAME"
              className="w-full p-2 border-2 rounded-xl border-[#C3D228] "
            />

            <input
              type="tel"
              id="phone"
              {...register("phone", {
                required: "Phone no. is required",
                pattern: {
                  value: "/[0-9]{10}$/,",
                  message: "Enter a valid 10-digit mobile number",
                },
              })}
              placeholder="PHONE NO."
              className="w-full p-2 border-2 rounded-xl border-[#C3D228] "
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-2 mb-4">
            <input
              type="text"
              id="course"
              placeholder="COURSE NAME"
              className="w-full p-2 border-2 rounded-xl border-[#C3D228] "
              {...register("course", {
                required: "Course Name is required",
              })}
            />
            <input
              type="text"
              id="address"
              placeholder="ADDRESS"
              className="w-full p-2 border-2 rounded-xl border-[#C3D228] "
              {...register("address", {
                required: "address is required",
              })}
            />
          </div>

          <div className="mb-4 px-2">
            <textarea
              id="message"
              {...register("message", {
                required: "Message is required",
                minLength: {
                  value: "10",
                  message: "Message must be greater than 10 characters",
                },
                maxLength: {
                  value: "200",
                  message: "Message must be 200 characters",
                },
              })}
              rows="4"
              placeholder="TYPE YOUR MESSAGE"
              className="w-full p-2 border-2 rounded-xl border-[#C3D228] "
            ></textarea>
          </div>

          <div className="text-center">
            <button className=" bg-[#192F59] text-white py-2 px-8 rounded-2xl hover:bg-blue transition">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
