import vikaas from "../assets/image/course-card-img/VIKAAS (JA).webp"
import vishwaas from "../assets/image/course-card-img/VISHWAAS (JF).webp"
import vijay from "../assets/image/course-card-img/VIJAY (JR).webp"
import saksham from "../assets/image/course-card-img/SAKSHAM (MA).webp"
import sambhav from "../assets/image/course-card-img/SAMBHAV (MF).webp"
import sampoorn from "../assets/image/course-card-img/sampoorn.jpeg"
import pinnacle from "../assets/image/course-card-img/pinnacle.jpeg"
import paradise from "../assets/image/course-card-img/paradise.jpeg"
import pioneer from "../assets/image/course-card-img/pioneer (1).jpeg"


export const courseData = [
    {
      link:"course/11th-course-jee",
      image: vikaas,
      courseName: "VIKAAS (11th Going)",
      courseDescription:
        "VIKAAS is specially designed course for 11th Going students, which perfectly prepare students for JEE Main and Advanced in span of two year. Two options available in “VIKAAS” batches...",
      targetExam: "JEE MAIN & ADVANCED",
    },
    {
      link:"course/12th-course-jee",
      image: vishwaas,
      courseName: "VISHWAAS (12th Going)",
      courseDescription:
        "VISHWAAS is specially designed course for 12th Going students, which perfectly prepare students for JEE Main and Advanced in span of ONE year. Two options available in “VISHWAAS” batches...",
      targetExam: "JEE MAIN & ADVANCED",
    },
    {
      link:"course/Dropper-jee-mains-advance",
      image:vijay,
      courseName: "VIJAY (12th PASS) Dropper Batch",
      courseDescription:
        "VIJAY is specially designed course for 12th Pass students, which perfectly prepare students for JEE Main and Advanced in span of one year...",
      targetExam: "JEE MAIN & ADVANCED -2025",
    },
    {
      link:"course/11th-neet",
      image: saksham,
      courseName: "SAKSHAM (11th Going)",
      courseDescription:
        "SAKSHAM is specially designed course for 11th Going students, which perfectly prepare students for NEET - UG in span of two years. Two options available in “SAKSHAM” Course:...",
      targetExam: "NEET: 2026",
    },
    {
      link:"course/12th-neet",
      image: sambhav,
      courseName: "SAMBHAV (12th Going) ONE YEAR",
      courseDescription:
        "SAMBHAV is specially designed course for 12th going students, which perfectly prepare students for NEET in span of one year...",
      targetExam: "NEET 2025",
    },
    {
      link:"course/dropper-neet",
      image: sampoorn,
      courseName: "SAMPOORN (12th PASS) Dropper Batch",
      courseDescription:
        "SAMPOORN is specially designed course for 12th Pass students, which perfectly prepare students for NEET in span of one year...",
      targetExam: "NEET -2025",
    },
    {
      link:"course/foundation-10th",
      image:pinnacle,
      courseName: "PINNACLE (10th Going)",
      courseDescription:
        "Pinnacle Course is designed for 10th going students International Junior Science Olympiad",
      targetExam:
        "NCERT, Board, Foundation of Competitive Exams like JEE and NEET",
    },
    {
      link:"course/foundation-9th",
      image:pioneer,
      courseName: "PIONEER (9th Going)",
      courseDescription:
        "PIONEER Course is designed for 9th going students International Junior Science Olympiad",
      targetExam:
        "NCERT, Board, Foundation of Competitive Exams like JEE and NEET",
    },
    {
      image: paradise,
      courseName: "PARADISE (8th Going)",
      courseDescription:
        "PARADISE Course is designed for 8th going students International Junior Science Olympiad",
      targetExam:
        "NCERT, Board, Foundation of Competitive Exams like JEE and NEET",
    },
  ];