import React from "react";

function BranchLocation() {
  return (
    <div>
      <div className="p-0 mt-10 lg:px-36">
        <div className="border border-[rgb(161,210,63)] rounded-lg flex flex-col items-center justify-center ">
          <div className="flex justify-center pt-0">
            <button className="text-white px-6 py-2 bg-[rgb(122,159,46)] rounded-b-xl">
              Science Division
            </button>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center gap-8 px-4 md:px-8 lg:px-24 w-full">
            <div className="w-full md:w-1/2 flex flex-col items-center p-6 ">
              <h2 className="text-lg font-bold mb-2 text-black text-center">
                South Tukoganj Campus-II:
              </h2>
              <p className="text-gray-700 mb-4 text-center">
                Near South Avenue Hotel, Indore-452001
              </p>
              <p className="font-medium text-gray-800 text-center">
                Phone: <a href="tel:+917389934553 "> 7389934553 </a> || <a href="tel:+917389934554"> 7389934554</a>
              </p>
            </div>
            <div className="hidden md:block w-[2px] h-full bg-gray-300" />
            <div className="w-full md:w-1/2 flex flex-col items-center p-6  ">
              <h2 className="text-lg font-bold mb-2 text-black text-center">
                Annapurna Campus-II:
              </h2>
              <p className="text-gray-700 mb-4 text-center">
                1st Floor, Meera's, Footi Kothi Square, Sudama Nagar,
                Indore-452009
              </p>
               
                <p className="font-medium text-gray-800 text-center">
                 Phone:<a href="tel:+917389934552">  7389934552 </a>|| <a href="tel:+917389934555"> 7389934555 </a>
              
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BranchLocation;
