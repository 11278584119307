import axios from "axios";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "../webServices/axiosInstance";
import Notiflix from "notiflix";
import Thankyou from "./Thankyou";
import OtpVerification from "./OtpVerifier";
import { Logo } from "../data/ImageData"
import moment from "moment";

const ResoSatRegisterModal = ({ setClose }) => {
  const navigate = useNavigate();
  const inputRef = useRef();
  const [error, setError] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [examDate, setExamDate] = useState(null);
  const [examSubject, setExamSubject] = useState(null);
  const [examCenter, setExamCenter] = useState(null);
  const [slotTime, setSlotTime] = useState(null);
  const [studentClass, setStudentClass] = useState(null);
  const [city, setCity] = useState(null);
  const [school, setSchool] = useState(null);
  const [mode, setMode] = useState("online");
  const [registerStatus, setRegisterStatus] = useState(false);

  async function registerStudent(userInfo) {
    try {
      const response = new Promise(async (resolve, reject) => {
        const register = await axiosClient().post(
          "/resosat/register",
          userInfo
        );
        console.log(register);
        resolve(register);
      });
      const data = await response;
      console.log(data);
      return data;
    } catch (error) {
      console.log(error.message);
      return { status: false };
    }
  }

  async function handleGenerateOtp() {
    if (!mobile || mobile.length < 10 || mobile == "") {
      inputRef.current.focus();
      setError("*Please ! Enter a valid mobile number.");
      return;
    }
    Notiflix.Loading.circle();
    let res = await axiosClient().post("/resosat/sendotp", { mobile });
    if (res.data.status) {
      setOtpStatus("generated");
      Notiflix.Loading.remove();
    } else {
      setError("*Please ! Enter a valid mobile number.");
      Notiflix.Loading.remove();
    }
    console.log(mobile);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      name: name,
      mobile: mobile,
      class: studentClass,
      city: city,
      school: school,
      slotTime: slotTime,
      examDate: examDate,
      exam: examSubject,
      mode: mode,
      examCenter: examCenter,
    };
    Notiflix.Loading.circle();
    const userregistered = await registerStudent(data);
    console.log(userregistered);
    if (userregistered.data.status) {
      if (userregistered.data.message === "Registered Successfully") {
        Notiflix.Notify.success(userregistered.data.message);
      } else {
        Notiflix.Notify.failure(userregistered.data.message);
      }
      setTimeout(() => {
        Notiflix.Loading.remove();
        setRegisterStatus(true);
      }, 1500);
    } else {
      Notiflix.Notify.failure(
        `Registration failed : ${userregistered.data.message}`
      );
      Notiflix.Loading.remove();
    }
  };

  console.log(moment("03-nov-2024").format("DD-MMM-YYYY"));

  return (
    <>
      <div
        className="modal fade show hideScrollBar  md:w-fit lg:w-fit h-fit m-auto"
        id="model1"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        
        aria-modal="true"
      >
        <div
          className="flex items-center justify-center h-screen"
          role="document"
        >
          <div id="UpdatePanel1 ">
            <div className="modal-content bg-white lg:p-4 sm:p-0 rounded-xl">
              <div className="modal-header p-4 flex border-b-2 bg-white justify-between items-center">
                <h4 className="text-xl lg:text-2xl font-medium md:font-semibold lg:font-bold">
                  ResoSAT Registration Form
                </h4>
                <div
                  onClick={() => {
                    setClose(false);
                  }}
                  className="shadow-xl px-2 border-2 cursor-pointer size-8 flex items-center justify-center  text-white font-bold"
                  style={{ background: "red" }}
                >
                  X
                </div>
              </div>
              <div className="modal-body bg-white">
                <div className="row">
                  {otpStatus === false && (
                    <div className="rounded-lg shadow-md  bg-indigo-600 lg:m-5 lg:p-24 p-12">
                      <img
                        src={Logo}
                        className="m-auto w-[250px]"
                        alt="resosatlogo"
                      />
                      <h3 className="text-white text-xl text-center my-4">
                        Register for Biggest scholarship Test
                      </h3>
                      <div className="mb-4">
                        <label
                          for="number"
                          className="block text-xl font-medium text-white"
                        >
                          Mobile No.
                        </label>
                        <input
                          ref={inputRef}
                          type="text"
                          onChange={(e) => {
                            setMobile(e.target.value);
                          }}
                          maxLength={10}
                          id="number"
                          required
                          className="mt-1 text-black block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder="*Enter mobile Number"
                        />
                        {error && (
                          <p className="text-yellow-300 mt-2 text-xl">
                            {error}
                          </p>
                        )}
                        <p className="mt-2 text-lg text-white ">
                          Note : Only one user can register with one mobile
                          number
                        </p>
                      </div>
                      <button
                        onClick={handleGenerateOtp}
                        className=" justify-center mt-2 w-full py-2 px-8 border hover:shadow-xl duration-200 hover:scale-95 hover:bg-[#82ac18] hover:text-white border-transparent shadow-sm text-xl font-bold rounded-md text-black bg-[#f6f6f6] "
                      >
                        Get OTP
                      </button>
                    </div>
                  )}
                  {otpStatus === "generated" && (
                    <OtpVerification
                      success={otpStatus}
                      setSuccess={setOtpStatus}
                      mobile={mobile}
                    />
                  )}
                  {otpStatus === true && (
                    <form
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                      className="rounded-lg shadow-md bg-indigo-600 py-8 px-6 lg:px-16 lg:w-[40vw] "
                    >
                      <h1 className="text-white flex justify-center font-bold text-2xl bg-black p-2 rounded-br-2xl rounded-tl-2xl lg:text-3xl mb-5">
                        Enroll For Scholarship Test
                      </h1>
                      <div
                        id="form-field-container"
                        className="grid grid-cols-2 gap-x-3"
                      >
                        <div className="mb-4">
                          <label
                            for="mode"
                            className="block  text-xl font-medium text-white"
                          >
                            Mode
                          </label>
                          <select
                            required
                            onChange={(e) => {
                              setMode(e.target.value);
                            }}
                            id="name"
                            className="mt-1 focus:text-white capitalize block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option className="text-black" value={"online"}>
                              Online
                            </option>
                            <option className="text-black" value={"offline"}>
                              Offline
                            </option>
                          </select>
                        </div>
                        {mode === "offline" && (
                          <div className="mb-4">
                            <label
                              for="examCenter"
                              className="block  text-xl font-medium text-white "
                            >
                              Exam Center
                            </label>
                            <select
                              required
                              onChange={(e) => {
                                setExamCenter(e.target.value);
                              }}
                              id="mode"
                              className="mt-1 focus:text-white text-black capitalize block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                              <option className=" text-black">
                                --choose center--
                              </option>
                              <option
                                className=" text-black"
                                value={"South Tukoganj center"}
                              >
                                South Tukoganj , Indore
                              </option>
                              <option
                                className=" text-black"
                                value={"Footi Kothi center"}
                              >
                                Footi Kothi , Indore
                              </option>
                            </select>
                          </div>
                        )}
                        <div className="mb-4">
                          <label
                            for="name"
                            className="block  text-xl font-medium text-white"
                          >
                            Student Name
                          </label>
                          <input
                            type="text"
                            required
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            id="name"
                            className="mt-1 focus:text-white capitalize block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="*Enter full Name"
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            for="city"
                            className="block text-xl font-medium text-white"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                            required
                            id="city"
                            className="mt-1 capitalize focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="*Enter your city"
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            for="school"
                            className="block text-xl font-medium text-white"
                          >
                            School
                          </label>
                          <input
                            type="text"
                            required
                            onChange={(e) => {
                              setSchool(e.target.value);
                            }}
                            id="school"
                            className="mt-1 capitalize focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="*Enter your School"
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            for="class"
                            className="block text-xl font-medium text-white"
                          >
                            class
                          </label>
                          <select
                            type="text"
                            onChange={(e) => {
                              setStudentClass(e.target.value);
                            }}
                            id="class"
                            required
                            className="mt-1 focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="*Enter your city"
                          >
                            <option className="text-black">
                              --select class--
                            </option>
                            <option className=" text-black" value="8th">
                              8<sup>th</sup>(going 9th)
                            </option>
                            <option className=" text-black" value="9th">
                              9<sup>th</sup>(going 10th)
                            </option>
                            <option className=" text-black" value="10th">
                              10<sup>th</sup>(going 11th)
                            </option>
                            <option className=" text-black" value="11th">
                              11<sup>th</sup>(going 12th)
                            </option>
                            <option className=" text-black" value="12th">
                              12<sup>th</sup>(passing 12th or dropper
                            </option>
                          </select>
                        </div>
                        {(studentClass === "10th" ||
                          studentClass === "11th" ||
                          studentClass === "12th") && (
                          <div className="mb-4 col-span-2">
                            <label
                              for="exam"
                              className="block text-xl font-medium text-white"
                            >
                              Subject
                            </label>
                            <select
                              type="text"
                              onChange={(e) => {
                                setExamSubject(e.target.value);
                              }}
                              id="exam"
                              required
                              className="mt-1 focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                              <option className="text-black">
                                --select Subject/Stream--
                              </option>
                              <option className=" text-black" value="neet">
                                BIO-NEET
                              </option>
                              <option className=" text-black" value="jee">
                                MATHS-JEE(MAIN + Adv.)
                              </option>
                            </select>
                          </div>
                        )}
                        <div className="mb-4">
                          <label
                            for="exam"
                            className="block text-xl font-medium text-white"
                          >
                            Exam Date
                          </label>
                          <select
                            type="text"
                            onChange={(e) => {
                              setExamDate(e.target.value);
                            }}
                            id="exam"
                            required
                            className="mt-1 focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="*Enter your city"
                          >
                            <option className="text-black">
                              --select Exam--
                            </option>

                            {mode === "offline" && (
                              <option
                                className=" text-black"
                                value="17-nov-2024"
                              >
                                17<sup>th</sup> Nov 2024
                              </option>
                            )}
                            {mode === "online" && (
                              <>
                                {moment(new Date()).format("DD-MMM-YYYY") !==
                                  "03-Nov-2024" && (
                                  <option
                                    className=" text-black"
                                    value="03-Nov-2024"
                                  >
                                    3<sup>rd</sup> Nov 2024
                                  </option>
                                )}
                                {moment(new Date()).format("DD-MMM-YYYY") !==
                                  "04-Nov-2024" && (
                                  <option
                                    className=" text-black"
                                    value="04-Nov-2024"
                                  >
                                    4<sup>th</sup> Nov 2024
                                  </option>
                                )}
                                {moment(new Date()).format("DD-MMM-YYYY") !==
                                  "05-Nov-2024" && (
                                  <option
                                    className=" text-black"
                                    value="05-Nov-2024"
                                  >
                                    5<sup>th</sup> Nov 2024
                                  </option>
                                )}
                                {moment(new Date()).format("DD-MMM-YYYY") !==
                                  "06-Nov-2024" && (
                                  <option
                                    className=" text-black"
                                    value="06-Nov-2024"
                                  >
                                    6<sup>th</sup> Nov 2024
                                  </option>
                                )}
                                {moment(new Date()).format("DD-MMM-YYYY") !==
                                  "07-Nov-2024" && (
                                  <option
                                    className=" text-black"
                                    value="07-Nov-2024"
                                  >
                                    7<sup>th</sup> Nov 2024
                                  </option>
                                )}
                              </>
                            )}
                          </select>
                        </div>
                        {mode === "offline" && (
                          <div className="mb-4">
                            <label
                              for="slot"
                              className="block text-xl font-medium text-white"
                            >
                              Slot
                            </label>
                            <select
                              type="text"
                              onChange={(e) => {
                                setSlotTime(e.target.value);
                              }}
                              id="slot"
                              required
                              className="mt-1 focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                              <option className="text-black">
                                --select Slot Time--
                              </option>
                              <option className=" text-black" value="01:00PM">
                                Slot 1
                              </option>
                              <option className=" text-black" value="04:00PM">
                                Slot 2
                              </option>
                            </select>
                          </div>
                        )}
                        <div className="mb-4 col-span-2">
                          <label
                            for="number"
                            className="block text-xl font-medium text-white"
                          >
                            Number
                          </label>
                          <input
                            type="text"
                            readOnly
                            id="number"
                            value={mobile}
                            required
                            className="mt-1 focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="*Enter mobile Number"
                          />
                          <p className="mt-2 text-lg text-white ">
                            Note : Only one user can register with one mobile
                            number
                          </p>
                        </div>
                      </div>
                      {/* <div>
                      <p className="font-semibold text-white">
                        Already Registered?{" "}
                        <span className="underline cursor-pointer text-orange-600">
                          click here to Login
                        </span>{" "}
                      </p>
                    </div> */}

                      <button
                        type="submit"
                        className=" justify-center mt-2 w-full py-2 px-8 border hover:shadow-xl duration-200 hover:scale-95 hover:bg-[#82ac18] hover:text-white border-transparent shadow-sm text-xl font-bold rounded-md text-black bg-[#f6f6f6] "
                      >
                        Submit
                      </button>
                    </form>
                  )}
                </div>
              </div>
              {/* Footer can be added here if needed */}
            </div>
          </div>
        </div>
      </div>
      {registerStatus && (
        <div className="h-screen z-[10000] flex fixed top-0 w-screen bg-[#1a1a1896] z-50">
          <Thankyou
            message={`You have been registered successfully. your UserId and Password will be sent on given mobile number shortly !`}
          />
        </div>
      )}
    </>
  );
};

export default ResoSatRegisterModal;
