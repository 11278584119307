import Hero from "../components/Hero";
import CardInsight from "../cards/CardInsight";
import CourseCardSection from "../cards/CardSection";
import StudentCommentSection from "../StudentReview/StudentCommentSection";
import PhotoLibrary from "../components/PhotoLibrary";
import Form from "../components/Form";
import BranchLocation from "../components/BranchLocation";
import Slider from "../components/Slider";

const HomePage = () => {
  return (
    <>
      <Hero />
      <CardInsight />
      <Slider/>
      <CourseCardSection />
      <StudentCommentSection />
      <PhotoLibrary />
      <Form />
      <BranchLocation />
    </>
  );
};

export default HomePage;
